import React, { useState } from 'react'
import { ListItem, ListItemPrefix } from "@material-tailwind/react";
import { BellAlertIcon, UserCircleIcon, NewspaperIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { MdAccountTree } from 'react-icons/md';

const PostSales = () => {
    const navigate = useNavigate();
    
    return (
        <>
            <hr className="my-2 border-black-gray-50" />

            <ListItem onClick={() => navigate("/Notification")} className="border-b-0 p-3 hover:bg-blue-gray-50">
                <ListItemPrefix>
                    <BellAlertIcon className="h-5 w-5 text-black" />
                </ListItemPrefix>
                Notification
            </ListItem>

            <ListItem onClick={() => alert("Coming Soon!")} className="border-b-0 p-3 hover:bg-blue-gray-50">
                <ListItemPrefix>
                    <MdAccountTree className="h-5 w-5 text-black" />
                </ListItemPrefix>
                Team
                <span className="inline-flex items-center rounded-md ml-1 bg-black px-2 py-1.5 text-xs font-bold text-white ring-1 ring-inset ring-green-600/20">
                    Coming Soon
                </span>
            </ListItem>

            <ListItem onClick={() => navigate("/DPS")} className="border-b-0 p-3 hover:bg-blue-gray-50">
                <ListItemPrefix>
                    <NewspaperIcon className="h-5 w-5 text-black" />
                </ListItemPrefix>
                DPS.
            </ListItem>
            <ListItem onClick={() => navigate("/profile")} className="border-b-0 p-3 hover:bg-blue-gray-50">
                <ListItemPrefix>
                    <UserCircleIcon className="h-5 w-5 text-black" />
                </ListItemPrefix>
                Profile
            </ListItem>

        </>
    )
}

export default PostSales
