import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import App from '../App';
import CheckPayments from '../All Components/CheckPayments';
import AllPayments from '../All Components/AllPayments';
import Employeelogin from '../All Components/Employeelogin';
import Attendance from '../All Components/AttendanceDetails/Attendance';
import LeadsDistribution from '../All Components/LeadsDistribution';
import Notification from '../All Components/Notification';
import LeadGen from '../All Components/LeadGen';
import DisplayProfile from '../All Components/Profile/DisplayProfile';
import team from '../All Components/Team Structure/OrgChart'
import DPS from '../All Components/DpsForm';
import AccessControl from '../All Components/Admin Access/AccessControl';
import LeadAssign from '../All Components/Admin Access/LeadAssign';
import LetsOnboard from '../All Components/Admin Access/LetsOnboard';
import ExpenseTracker from '../All Components/Admin Access/ExpenseTracker';
import PostNotify from '../All Components/Admin Access/PostNotify';
import DirectPayments from '../All Components/DirectPayments';
import LeadsList from '../All Components/LeadsList';
import Page404 from '../All Components/Page404';
import ProtectedRoute from './ProtectedRoute';

// Role-based route configurations
const ROUTE_ROLES = {
  allRoles: [
    "Sales", "Employee", "Admin", "Post Sales", "Marketing", 
    "Tech", "Human Resource", "Operations", "B2B", "Heads"
  ],
  SALES_POSTSALES: ["Sales", "Post Sales"],
  SALES_ONLY: ["Sales"],
  ADMIN_ONLY: ["Admin"]
};

// Route configurations
const ROUTES = {
  public: [
    { path: "/Employeelogin", element: Employeelogin, roles: ROUTE_ROLES.allRoles },
    { path: "/testingapi", element: LeadsList, roles: ROUTE_ROLES.allRoles }
  ],
  app: [
    { path: "CheckPayments", element: CheckPayments, roles: ROUTE_ROLES.allRoles },
    { path: "AllPayments", element: AllPayments, roles: ROUTE_ROLES.allRoles },
    { path: "directpayments", element: DirectPayments, roles: ROUTE_ROLES.SALES_ONLY },
    { path: "Attendance", element: Attendance, roles: ROUTE_ROLES.allRoles },
    { path: "LeadsDistribution", element: LeadsDistribution, roles: ROUTE_ROLES.SALES_POSTSALES },
    { path: "Notification", element: Notification, roles: ROUTE_ROLES.SALES_POSTSALES },
    { path: "leadgen", element: LeadGen, roles: ROUTE_ROLES.SALES_POSTSALES },
    { path: "team", element: team, roles: ROUTE_ROLES.allRoles },
    { path: "profile", element: DisplayProfile, roles: ROUTE_ROLES.allRoles },
    { path: "DPS", element: DPS, roles: ROUTE_ROLES.SALES_POSTSALES },
    { path: "leadsassign", element: LeadAssign, roles: ROUTE_ROLES.ADMIN_ONLY },
    { path: "accesscontrol", element: AccessControl, roles: ROUTE_ROLES.ADMIN_ONLY },
    { path: "postnotification", element: PostNotify, roles: ROUTE_ROLES.ADMIN_ONLY },
    { path: "onboard", element: LetsOnboard, roles: ROUTE_ROLES.ADMIN_ONLY },
    { path: "expensetracker", element: ExpenseTracker, roles: ROUTE_ROLES.ADMIN_ONLY }
  ]
};

const AppRoute = () => {
  const userRole = useSelector((state) => state.roles.role);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        {ROUTES.public.map(({ path, element: Element, roles }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute role={userRole} allowedRoles={roles}>
                <Element />
              </ProtectedRoute>
            }
          />
        ))}

        {/* App Routes */}
        <Route path="/" element={<App />}>
          {ROUTES.app.map(({ path, element: Element, roles }) => (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute role={userRole} allowedRoles={roles}>
                  <Element />
                </ProtectedRoute>
              }
            />
          ))}
        </Route>

        {/* 404 Route */}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;