import { useEffect, useState } from 'react'
import { Button, CardBody, Dialog, DialogBody, DialogFooter, DialogHeader, typography, Typography } from '@material-tailwind/react';
import Typewriter from 'typewriter-effect';
import { useForm } from 'react-hook-form';
import { MdOutlineError } from 'react-icons/md';
import { TiMessages } from "react-icons/ti";
import { GrAnnounce } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeesDetails } from './All Components/redux/slice/employeeSlice'
import { postAnnouncement, allNotifications } from './All Components/api/notificationApi';


const NotificationTab = () => {
  const [announcementData, setAnnouncementData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(""); // State for selected department
  const [filteredAnnouncements, setFilteredAnnouncements] = useState([]); // State for filtered announcements

  const [size, setSize] = useState(false);

  const { register, handleSubmit, reset, watch, formState: { errors, isSubmitting } } = useForm();
  const watchedFields = watch();
  const isFormEmpty = !Object.values(watchedFields).some((value) => value);
  const allDetails = useSelector(state => state.employeesDetails);
  const { Employee_Name, Employee_Id, Department } = allDetails.data || {};

  const dispatch = useDispatch();

  const handleOpen = (value) => setSize(value);

  useEffect(() => {
    dispatch(fetchEmployeesDetails());
  }, [dispatch]);

  // Fetch announcements on component mount
  useEffect(() => {
    const loadAnnouncements = async () => {
      try {
        const response = await allNotifications();
        setAnnouncementData(response.data);
      } catch (error) {
        console.error("Failed to load announcements:", error);
      }
    };
    loadAnnouncements();
  }, [dispatch]);

  const handlePostAnnouncement = () => {
    handleOpen("xxl");
    setSelectedDepartment("");
  }

  const formatDateTime = () => {
    const date = new Date();
    // Format date
    const formattedDate = date.toLocaleDateString("en-US", {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    // Format time
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // For AM/PM format
    });

    return `${formattedDate}, ${formattedTime}`;
  };

  const onSubmit = async (data) => {
    try {
      const formattedDate = formatDateTime();
      const announcementDetails = {
        announcementTitle: data.announcementTitle,
        announcementDate: formattedDate,
        announcementMessage: data.announcement,
        announcementBy: Employee_Name,
      };
      const announcementToSave = {
        department: data.department,
        announcementsDetails: [announcementDetails],
      };
      const response = await postAnnouncement(announcementToSave);
      alert("Announcement sent successfully!");
      setAnnouncementData((prev) => [...prev, response.data]);
      reset();
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Failed to send Announcement";
      alert(errorMessage);
    }
  };

  useEffect(() => {
    // Filter announcements based on selected department
    if (selectedDepartment) {
      const filtered = announcementData.find(department => department.department === selectedDepartment);
      setFilteredAnnouncements(filtered ? filtered.announcementsDetails : []);
    } else {
      setFilteredAnnouncements([]);
    }
  }, [selectedDepartment, announcementData]);

  const handleSubmitAnnounce = handleSubmit(onSubmit);

  return (
    <>
      <div className="pt-3 pb-4 z-10 px-4 rounded-border bg-transparent">
        <Typography variant="text" color="blue-gray" className="whitespace-nowrap font-bold mt-1 flex items-center text-center md:text-left">
          <div className="flex items-center justify-between w-full">
            <h1 className="flex">
              Share Message 📝&nbsp;
              <Typewriter
                options={{
                  strings: [`<span style="color: #16a34a";">with your Employees!</span>`],
                  autoStart: true,
                  loop: true,
                  delay: 200,
                  deleteSpeed: 100,
                }}
              />
            </h1>
            <div className="absolute right-4">
              <Button className="bg-black w-auto py-4 px-4" onClick={() => handlePostAnnouncement()}>
                <span>Post Message 🔔</span>
              </Button>
            </div>
          </div>
        </Typography>
      </div>
      <div className="mt-1">
        <CardBody className="h-screen overflow-y-auto px-0 text-gray-700 rounded-xl border border-gray-300 pt-0">
          <div className="pt-3 pb-4 z-10 px-4 bg-transparent">
            <form onSubmit={handleSubmitAnnounce}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-2">
                {/* Department to be send  */}
                <div className="relative">
                  <label htmlFor="text">
                    <Typography
                      variant="medium"
                      color="blue-gray"
                      className="block font-medium mb-1">
                      Send Message To:
                    </Typography>
                  </label>
                  <div className="relative">
                    <TiMessages className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                    <select {...register("department", { required: true })}
                      className="w-full py-2.5 pl-10 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent focus:border-gray-500 focus:ring-gray-900/10"
                      placeholder="Select Department">
                      <option value="">Choose department</option>
                      <option value="All departments">All departments</option>
                      <option value="Operations">Operations</option>
                      <option value="Sales">Sales</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Post Sales">Post Sales</option>
                      <option value="Human Resource">Human Resource</option>
                      <option value="Heads">Heads</option>
                      <option value="Tech">Tech</option>
                      <option value="Admin">Admin</option>
                      <option value="B2B">B2B</option>
                    </select>
                  </div>
                  <div className="mt-2 h-2">
                    {errors.department && (
                      <p className="text-red-500 text-xs mt-2 flex align-middle gap-1">
                        <MdOutlineError className="w-4 h-4" />Department is required
                      </p>
                    )}
                  </div>
                </div>

                {/* Announcement Title  */}
                <div className='relative'>
                  <label htmlFor="text">
                    <Typography
                      variant="medium"
                      color="blue-gray"
                      className="block font-medium mb-1">
                      Announcement Title:
                    </Typography>
                  </label>
                  <div className="relative">
                    <GrAnnounce className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                    <input
                      placeholder='Enter Title'
                      type="text"
                      {...register("announcementTitle", { required: true, minLength: 3, maxLength: 70 })}
                      className="w-full py-2 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-gray-500 focus:ring-gray-900/10"
                      autocomplete="off"
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="mt-2 h-2">
                    {errors.announcementTitle && (
                      <p className="text-red-500 text-xs flex align-middle gap-1">
                        <MdOutlineError className="w-4 h-4 shrink-0" />
                        <span className="truncate">Announcement title is required</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* Announcement Field  */}
              <div>
                <label htmlFor="text">
                  <Typography
                    variant="medium"
                    color="blue-gray"
                    className="block font-medium mb-1">
                    Announcement:
                  </Typography>
                </label>
                <div className="relative w-full">
                  <textarea
                    placeholder="Enter Message"
                    type="text"
                    {...register("announcement", { required: true, minLength: 2 })}
                    onInput={(e) => {
                      e.target.style.height = "auto"; // Reset height
                      e.target.style.height = `${e.target.scrollHeight}px`; // Set to content height
                    }}
                    className="w-full h-auto py-2 pl-2 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-gray-500 focus:ring-gray-900/10 resize-none"
                    autoComplete="off"
                  />
                </div>
                <div className="mt-1 h-2">
                  {errors.announcement && (
                    <p className="text-red-500 text-xs flex align-middle gap-1">
                      <MdOutlineError className="w-4 h-4 shrink-0" />
                      <span className="truncate">Announcement is required</span>
                    </p>
                  )}
                </div>
              </div>
              <input
                type="submit"
                className={`mt-4 bg-black text-white p-3 w-auto rounded-lg cursor-pointer hover:bg-gray-900 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                value={isSubmitting ? "Sending..." : "Send Announcement"}
                disabled={isSubmitting}
              />
              <button
                onClick={() => reset()}
                className={`w-32 md:w-auto ml-2 mt-4 sm:mt-0 border border-red-300 rounded-lg px-5 py-2 text-red-600 bg-white focus:outline-none focus:ring-2 focus:ring-red-700 disabled:opacity-50 disabled:cursor-not-allowed ${isSubmitting ? "hover:bg-white hover:text-red-600" : "bg-white text-red"}`}
                disabled={isSubmitting || isFormEmpty}>
                Reset
              </button>
            </form>
            <Dialog open={size === "xxl"} size={size || "md"} handler={handleOpen}
              className="h-full overflow-y-auto m-2">
              <DialogHeader className="text-center sm:text-left">
                <div className="flex items-center justify-between w-full">
                  <h1 className="flex">
                    Check your 📢&nbsp;
                    <Typewriter
                      options={{
                        strings: [`<span style="color: #16a34a";">Post announcement for the department!</span>`],
                        autoStart: true,
                        loop: true,
                        delay: 200, // Adjust typing speed
                        deleteSpeed: 100, // Adjust delete speed
                      }}
                    />
                  </h1>
                  <div className="absolute right-4">
                    <Button className="bg-black w-auto py-4 px-4" onClick={() => handleOpen(null)}>
                      <span>Back To HomePage 🏠</span>
                    </Button>
                  </div>
                </div>
              </DialogHeader>
              <DialogBody>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                  <div className="mb-4">
                    <label htmlFor="department" className="block text-gray-700 font-bold mb-2">Select Department:</label>
                    <select
                      id="department"
                      value={selectedDepartment}
                      onChange={(e) => setSelectedDepartment(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">-- Select a Department --</option>
                      {announcementData.map(department => (
                        <option key={department._id} value={department.department}>
                          {department.department}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Render announcements for the selected department */}
                  {filteredAnnouncements.length > 0 ? (
                    filteredAnnouncements.map(announcement => (
                      <div key={announcement._id} className="border p-2 rounded-lg shadow mb-2">
                        <Typography className="font-semibold text-md">
                          <span className='text-black font-semibold'>Title: </span> {announcement.announcementTitle}
                        </Typography>
                        <Typography className="text-sm">
                          <span className='text-black font-semibold'>Date: </span> {announcement.announcementDate}
                        </Typography>
                        <Typography className="text-sm">
                          <span className='text-black font-semibold'>Message: </span> {announcement.announcementMessage}
                        </Typography>
                        <Typography className="text-sm">
                          <span className='text-black font-semibold'>By: </span> {announcement.announcementBy}
                        </Typography>
                      </div>
                    ))
                  ) : (
                    <Typography variant="sm" color="gray" className="font-normal text-blue-gray-500">
                      No announcements available for this department.
                    </Typography>
                  )}
                </div>
              </DialogBody>

              {/* <DialogFooter className="flex justify-between items-center">
                <Typography variant="sm" className="font-normal text-black opacity-80">
                  <b style={{ color: "red", fontWeight: "bold", backgroundColor: "white" }}>Note:</b>
                  <span> List of all Notifications posted from your end.</span>
                </Typography>
              </DialogFooter> */}
            </Dialog>
          </div>
        </CardBody>
      </div>
    </>
  )
}

export default NotificationTab
