import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import todoReducer from './slice/employeeSlice';
import roleSlice from './slice/roles';
import leadGenSlice from './slice/leadGenSlice';

const employeesDetailsPersistConfig = {
    key: 'employeesDetails',
    storage,
};

const rolesPersistConfig = {
    key: 'roles',
    storage,
};

// Combine all reducers
const rootReducer = combineReducers({
    employeesDetails: persistReducer(employeesDetailsPersistConfig, todoReducer),
    roles: persistReducer(rolesPersistConfig, roleSlice), 
    leadgenfromlp: leadGenSlice,
});

// Persist config for the root reducer (optional, if you want to persist the entire store)
const rootPersistConfig = {
    key: 'root',
    storage,
    whitelist: [], // Leave empty if you don't want to persist the entire store
};

// Create the persisted root reducer
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

// Create the store
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST'], // Ignore non-serializable actions
            },
        }),
});
// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
