import React, { useState } from 'react';

const OrgChart = () => {
  const [expandedNodes, setExpandedNodes] = useState({
    founder: true,
    ceo: true,
    cto: true,
    cfo: true,
    vp_marketing: true
  });

  const toggleExpand = (nodeId) => {
    setExpandedNodes({
      ...expandedNodes,
      [nodeId]: !expandedNodes[nodeId]
    });
  };

  // Node component for team members
  const TeamNode = ({ id, name, title, children, level = 0 }) => {
    const hasChildren = children && children.length > 0;
    
    return (
      <div className="flex flex-col items-center">
        {/* Node */}
        <div 
          className={`relative mb-4 p-3 rounded-lg shadow-md border-2 border-blue-300 
                    ${level === 0 ? 'bg-blue-100' : 
                      level === 1 ? 'bg-green-100' : 
                      level === 2 ? 'bg-yellow-100' : 'bg-purple-100'}`}
        >
          <div className="text-center">
            <h3 className="font-bold text-gray-800">{name}</h3>
            <p className="text-sm text-gray-600">{title}</p>
          </div>
          
          {/* Expand/collapse button for nodes with children */}
          {hasChildren && (
            <button 
              onClick={() => toggleExpand(id)}
              className="absolute -bottom-3 left-1/2 transform -translate-x-1/2 w-6 h-6 rounded-full bg-gray-200 border border-gray-300 flex items-center justify-center text-xs"
            >
              {expandedNodes[id] ? '-' : '+'}
            </button>
          )}
        </div>
        
        {/* Children container */}
        {hasChildren && expandedNodes[id] && (
          <div className="relative">
            {/* Vertical connector */}
            <div className="absolute left-1/2 -top-4 w-px h-4 bg-gray-400"></div>
            
            {/* Horizontal connector for multiple children */}
            {children.length > 1 && (
              <div className="absolute top-0 left-0 right-0 h-px bg-gray-400 mx-auto" 
                  style={{ width: `${Math.max((children.length - 1) * 80, 0)}px` }}>
              </div>
            )}
            
            {/* Children nodes */}
            <div className={`flex flex-wrap justify-center gap-4 md:gap-8 ${level < 2 ? 'pt-4' : ''}`}>
              {children.map((child, index) => (
                <div key={child.id} className="flex flex-col items-center">
                  {children.length > 1 && (
                    <div className="w-px h-4 bg-gray-400"></div>
                  )}
                  <TeamNode {...child} level={level + 1} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Team structure data
  const teamData = {
    id: "founder",
    name: "John Smith",
    title: "Founder",
    children: [
      {
        id: "ceo",
        name: "Jane Doe",
        title: "CEO",
        children: [
          {
            id: "cto",
            name: "Mike Johnson",
            title: "CTO",
            children: [
              {
                id: "lead_dev",
                name: "Sarah Williams",
                title: "Lead Developer",
                children: [
                  {
                    id: "frontend_dev",
                    name: "Alex Brown",
                    title: "Frontend Developer",
                    children: []
                  },
                  {
                    id: "backend_dev",
                    name: "Cris Lee",
                    title: "Backend Developer",
                    children: []
                  }
                ]
              },
              {
                id: "devops",
                name: "Robert Garcia",
                title: "DevOps Engineer",
                children: []
              }
            ]
          },
          {
            id: "cfo",
            name: "David Wilson",
            title: "CFO",
            children: [
              {
                id: "finance_manager",
                name: "Lisa Thompson",
                title: "Finance Manager",
                children: []
              },
              {
                id: "accountant",
                name: "James Taylor",
                title: "Senior Accountant",
                children: []
              }
            ]
          },
          {
            id: "vp_marketing",
            name: "Emily Davis",
            title: "VP of Marketing",
            children: [
              {
                id: "marketing_manager",
                name: "Ryan Moore",
                title: "Marketing Manager",
                children: []
              },
              {
                id: "social_media",
                name: "Jessica Martin",
                title: "Social Media Specialist",
                children: []
              }
            ]
          }
        ]
      }
    ]
  };

  return (
    <div className="w-full overflow-auto py-8 px-4">
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Company Organization Chart</h2>
        <p className="text-gray-600">Click + or - to expand or collapse sections</p>
      </div>
      
      <div className="inline-block min-w-full">
        <div className="flex justify-center">
          <TeamNode {...teamData} />
        </div>
      </div>
    </div>
  );
};

export default OrgChart;