import axios from "axios";
import config from "../../config.js";

export const fetchDirectPayment = async (Employee_Id) => {
  const token = localStorage.getItem("Access Token");
  try {
    const response = await axios.get(`${config.hostedUrl}/directPayment/${Employee_Id}`, {
      headers: {
        Authorization: token,
      }
    });
    return response.data.records;
  } catch (error) {
    console.error("Error fetching payments:", error);
    return [];
  }
};

export const fetchDpsById = async (Employee_Id) => {
  const token = localStorage.getItem("Access Token");
  try {
    const response = await axios.get(`${config.hostedUrl}/dpsForm/dpsFormDataByEmployeeId/${Employee_Id}`, {
      headers: {
        Authorization: token,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching payments:", error);
    return [];
  }
};
