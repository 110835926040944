import React, { useEffect, useState } from "react";
import { Avatar, Card, Typography, List, IconButton, ListItem, ListItemPrefix} from "@material-tailwind/react";
import { Bars3Icon, PowerIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import Birthday from "../Birthday.js";
import avatar from '../../assets/avatar.jpg';
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeesDetails } from "../redux/slice/employeeSlice";
import { setSelectedRole, setRole } from "../redux/slice/roles.js";
import Admin from "./Admin.js";
import Sales from "./Sales.js";
import PostSales from "./PostSales.js";
import Employee from "./Employee.js";
import useLogout from "../utils/auth.js";
import { fetchAccessDetails } from "../api/accessControlApi.js";


const getEmojiForRole = (role) => {
  switch (role) {
    case "Sales":
      return "💼";
    case "Post Sales":
      return "👨‍💻";
    case "Human Resource":
      return "👥";
    default:
      return "⚙️";
  }
};

const SideBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [redirectedToDefault, setRedirectedToDefault] = useState(false);
  const allDetails = useSelector(state => state.employeesDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.roles.role);
  const selectedRole = useSelector((state) => state.roles.selectedRole) || role[0] || "";

  const { Designation, Employee_Id, Employee_Name } = allDetails.data || {};

  const handleLogout = useLogout();

  useEffect(() => {
    if (!selectedRole && role.length > 0) {
      dispatch(setSelectedRole({ selectedRole: role[0] }));
    }
  }, [role, selectedRole, dispatch]);

  // Fetch employee details on component mount
  useEffect(() => {
    dispatch(fetchEmployeesDetails());
  }, [dispatch]);

  const displayEmployeeName = Employee_Name ? Employee_Name.slice(0, 12) : '';

  useEffect(() => {
    const fetchRoleAccess = async () => {
      const token = localStorage.getItem("Access Token");
      if (!Employee_Id) return;
      try {
        const response = await fetchAccessDetails(Employee_Id,
          {
            headers:
              { Authorization: token }
          });
        const { roles } = response.data
        if (roles && Array.isArray(roles)) {
          dispatch(setRole({ role: roles }));
          dispatch(setSelectedRole({ selectedRole: roles[0] || "" }));
        } else {
          alert("Invalid role data. Please re-login.");
          navigate("/Employeelogin", { replace: true });
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoleAccess();
  }, [Employee_Id, dispatch]);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (!isLoggedIn) {
      navigate('/Employeelogin', { replace: true });
    }
  }, [navigate]);

  const authorizedRoles = useSelector((state) => state.roles.role) || [];
  const rolesArray = Array.isArray(authorizedRoles) ? authorizedRoles : [];

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath !== "/CheckPayments") {
      setRedirectedToDefault(false);
    }
  }, [window.location.pathname]);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const customColor = '#000000';

  return (
    <div className="flex mt-1 ml-2 text-gray-700 rounded-xl border border-gray-300 border-b-0 custom-shadow z-50">
      {/* Mobile Toggle Button */}
      <div className="lg:hidden">
        <IconButton variant="text" className="m-2" onClick={toggleSidebar}>
          <Bars3Icon className="h-6 w-6 text-gray-700" />
        </IconButton>
      </div>

      {/* Sidebar Container */}
      <Card className={`${isSidebarOpen ? 'block' : 'hidden'} lg:block lg:w-64 w-full lg:max-w-[18rem] h-full p-4 bg-white custom-shadow overflow-y-auto z-50 overflow-x-hidden`}>
        <div className="mb-3 flex items-center gap-4 p-4 bg-blue-gray-50 text-gray-700 rounded-xl border border-gray-300 border-b-0 custom-shadow">
          <Avatar
            src={avatar}
            alt="avatar"
            withBorder={true}
            className="p-0.5"
            style={{ borderColor: customColor, borderWidth: '2px' }}
          />
          <div>
            <Typography className="font-bold text-black">{displayEmployeeName}</Typography>
            <Typography variant="small" color="gray" className="font-normal">
              {Designation}
            </Typography>
            <span className="inline-flex items-center rounded-md bg-black px-2 py-1.5 text-xs font-bold text-white ring-1 ring-inset ring-green-600/20">
              {Employee_Id || "login again"}
            </span>
          </div>
        </div>

        <select
          className="focus:ring-0 w-full border border-black rounded-md p-2 border-2 cursor-pointer transition-all duration-300 ease-in-out"
          value={selectedRole}
          onChange={(e) => {
            const newRole = e.target.value;
            if (role.includes(newRole)) {
              dispatch(setSelectedRole({ selectedRole: newRole }));

              if (!redirectedToDefault) {
                navigate("/CheckPayments", { replace: true });
                setRedirectedToDefault(true);
              }
            } else {
              alert("You are not authorized to access this role.");
            }
          }}
        >
          {rolesArray.length === 0 ? (
            null
          ) : (
            rolesArray?.map((role, index) => (
              <option key={index} className="sm:text-sm bg-black text-white" value={role}>
                {getEmojiForRole(role)} {role}
              </option>
            ))
          )}
        </select>
        <hr className="mt-2 border-black-gray-90" />

        <List>

          {(selectedRole === "Sales") && (
            <Sales />
          )}

          {selectedRole === "Admin" && (
            <Admin />
          )}

          {selectedRole === "Post Sales" && (
            <PostSales />
          )}

          {selectedRole === "Employee" && (
            <Employee />
          )}


          <ListItem onClick={handleLogout} style={{ "color": "red" }}>
            <ListItemPrefix>
              <PowerIcon className="h-5 w-5" />
            </ListItemPrefix>
            Logout
          </ListItem>
        </List>
        <Birthday />
      </Card>
    </div>
  );
};

export default SideBar;
