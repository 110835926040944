import React, { useCallback, useEffect, useState } from 'react';
import LottieFile from '../LottieFile.js';
import { Button, Card, CardBody, Chip, Drawer, IconButton, Input, List, ListItem, Typography } from '@material-tailwind/react';
import config from '../../config.js';
import { FaWhatsapp } from "react-icons/fa";
import { MagnifyingGlassIcon, UserGroupIcon, Cog8ToothIcon, TrashIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import useSWR from 'swr';
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeesDetails } from "../redux/slice/employeeSlice.js";
import { provideAccessControl, fetchAccessDetails } from '../api/accessControlApi.js';

const TABLE_HEAD = ["Employee Id", "Name", "Access", "Phone", "Designation", "Employee Type", "DOJ", "Action"];

const SkeletonRow = ({ count }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <tr key={index} className="animate-pulse border-t border-b border-gray-300">
          <td className="p-3">
            <div className="h-4 bg-gray-300 rounded w-24"></div>
          </td>
          <td className="p-3">
            <div className="h-4 bg-gray-300 rounded w-32"></div>
          </td>
          <td className="p-3">
            <div className="h-4 bg-gray-300 rounded w-20"></div>
          </td>
          <td className="p-3">
            <div className="h-4 bg-gray-300 rounded w-28"></div>
          </td>
          <td className="p-3">
            <div className="h-4 bg-gray-300 rounded w-24"></div>
          </td>
          <td className="p-3">
            <div className="h-4 bg-gray-300 rounded w-20"></div>
          </td>
          <td className="p-3">
            <div className="h-4 bg-gray-300 rounded w-24"></div>
          </td>
          <td className="p-3 text-center">
            <div className="h-6 w-6 bg-gray-300 rounded-full"></div>
          </td>
        </tr>
      ))}
    </>
  );
};

const AccessControl = () => {
  const [openRight, setOpenRight] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleForDelete, setSelectedRoleDelete] = useState(null);
  const [accessGrantedFor, setAccessGrantedFor] = useState(null);
  const [accessDetails, setAccessDetails] = useState(null);
  const [err, setErr] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeRoles, setEmployeeRoles] = useState([]);

  const dispatch = useDispatch();

  const allDetails = useSelector(state => state.employeesDetails);
  const { Employee_Id } = allDetails.data || {};

  const openDrawerRight = () => setOpenRight(true);
  const closeDrawerRight = () => setOpenRight(false);
  const token = localStorage.getItem("Access Token");


  useEffect(() => {
    dispatch(fetchEmployeesDetails());
    fetchEmployeeAccess(Employee_Id);
  }, [dispatch]);

  const fetcher = (url) =>
    fetch(url, {
      headers: {
        Authorization: token,
      },
    }).then((res) => res.json());

  const { data, error, isLoading } = useSWR(`${config.hostedUrl}/employee/allemployees`, fetcher);


  const fetchAccessControl = useCallback(async () => {
    try {
      const response = await provideAccessControl();
      setAccessGrantedFor(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [provideAccessControl]);

  const fetchEmployeeAccess = useCallback(async (Employee_Id) => {
    if (!Employee_Id) return;
    try {
      const response = await fetchAccessDetails(Employee_Id);
      setAccessDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [fetchAccessDetails]);


  useEffect(() => {
    if (accessGrantedFor?.length > 0) {
      const roles = accessGrantedFor
        .filter((entry) => entry.employeeIds.includes(selectedEmployee?.Employee_Id))
        .map((entry) => entry.role);
      setEmployeeRoles(roles);
    }
  }, [accessGrantedFor, selectedEmployee?.Employee_Id, fetchEmployeeAccess]);


  const handleEmployeeClick = (employee) => {
    fetchAccessControl();
    setSelectedEmployee(employee);
    openDrawerRight();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredData = data ? data?.filter((employee) =>
    employee.Employee_Name.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];


  const grantAccess = async () => {
    if (!selectedRole) {
      setErr("Please select a role.");
      setTimeout(() => {
        setErr("");
      }, 3000);
      return;
    }

    const roleToSave = {
      "Employee_Id": selectedEmployee.Employee_Id,
      "role": selectedRole
    }

    if (roleToSave.Employee_Id === Employee_Id) {
      alert("You can't grant access to yourself");
      setSelectedRole('');
      return;
    }
    try {
      const response = await axios.post(`${config.hostedUrl}/accesscontrol`, roleToSave, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      });
      alert("Submit successfully", response.status);
      closeDrawerRight();
      setSelectedRole('');
      setErr("");
    } catch (error) {
      const errorMessage = error.response.data.message;
      console.log(errorMessage);
    }
  }

  const handleDelete = async (role) => {
    setSelectedRoleDelete(role);
    const employeeId = selectedEmployee?.Employee_Id?.trim();
    try {
      if (!employeeId || !role) {
        alert("Employee ID and Role are required to proceed.");
        return;
      }

      if (employeeId === Employee_Id) {
        alert("You can't delete your own access.");
        setSelectedRole('');
        return;
      }

      const isConfirmed = window.confirm(`Are you sure you want to delete the employee with role ${role}?`);
      if (!isConfirmed) {
        return;
      }

      const response = await axios.delete(`${config.hostedUrl}/accesscontrol/${employeeId}/${role}`,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );
      alert(response.data.message, selectedRoleForDelete);
      closeDrawerRight();
    } catch (error) {
      console.error("Error deleting access control:", error);
      const errorMsg = error.response?.data?.message || "An error occurred. Please try again.";
      alert(errorMsg); // Show error message
    }
  };

  return (
    <>
      <div className="flex w-full opacity-1">
        <LottieFile />
        <Card className="w-full mx-2 opacity-1 bg-custom shadow-none">
          <div className="mt-1">
            <CardBody className="pt-1 h-screen overflow-hidden px-0 text-gray-700 rounded-xl border border-gray-300">
              <div className="sticky top-0 z-20 bg-white shadow-md px-2">
                <div className="px-2 py-2 flex flex-col items-center justify-between gap-2 md:flex-row">
                  <Typography
                    variant="text"
                    color="blue-gray"
                    className="flex items-center justify-center whitespace-nowrap font-bold"
                  >
                    Total Employees<UserGroupIcon className="w-8 h-8 ml-2" />: {data?.length}
                  </Typography>
                  <div className="w-full md:w-72">
                    <Input
                      className="rounded-md focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                      labelProps={{ className: "hidden" }}
                      icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                      placeholder="Search Employee..."
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto h-[calc(100vh-64px)]">
                <table className="min-w-full table-auto text-left border-collapse">
                  <thead className="bg-black sticky top-0 z-10">
                    <tr>
                      {TABLE_HEAD.map((head, index) => (
                        <th key={head} className={`border-y border-blue-gray-100 p-3 ${index === 3 ? "text-center" : ""}`}>
                          <Typography
                            color="black"
                            className="font-bold text-white text-sm"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <SkeletonRow count={20} />
                    ) : filteredData.length || accessDetails?.length > 0 ? (
                      filteredData.map((employee) => (
                        <tr key={employee.id} className="border-t border-b border-gray-300 hover:bg-gray-100">
                          <td className="p-3">
                            <Typography variant="small" color='blue-gray' className="font-normal text-base">
                              {employee.Employee_Id}
                            </Typography>
                          </td>
                          <td className="flex p-3">
                            <Typography variant="small" color='blue-gray' className="font-normal text-base">
                              {employee.Employee_Name}
                            </Typography>
                          </td>
                          <td className="p-3">
                            <Typography variant="small" color='blue-gray' className="font-normal text-base font-bold text-black">
                              {employee.Role.map(role => role.charAt(0)).join(", ")}
                            </Typography>
                          </td>
                          <td className="p-3">
                            <div className="flex items-center space-x-2">
                              {employee.Phone && (
                                <a
                                  href={`https://wa.me/${employee.Phone}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-green-400 hover:text-green-700"
                                >
                                  <FaWhatsapp className="w-6 h-6" />
                                </a>
                              )}
                              <Typography variant="small" color='blue-gray' className="font-normal text-base">
                                {employee.Phone}
                              </Typography>
                            </div>
                          </td>
                          <td className="p-3">
                            <Typography variant="small" color='blue-gray' className="font-normal text-base">
                              {employee.Designation}
                            </Typography>
                          </td>
                          <td className="p-3">
                            <Typography variant="small" color='blue-gray' className="font-normal text-base">
                              {employee.Employee_Type}
                            </Typography>
                          </td>
                          <td className="p-3">
                            <Typography variant="small" color='blue-gray' className="font-normal text-base">
                              {employee.DOJ}
                            </Typography>
                          </td>
                          <td className="p-3">
                            <Typography variant="small" className="font-normal cursor-pointer">
                              <Cog8ToothIcon
                                className='w-6 h-6 text-gray-500 hover:text-black'
                                onClick={() => handleEmployeeClick(employee)} />
                            </Typography>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={TABLE_HEAD.length} className="text-center p-4 text-sm">Error in loading data</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Drawer
                  placement="right"
                  open={openRight}
                  onClose={closeDrawerRight}
                  className="p-2"
                >
                  <div>
                    <div className="flex items-center justify-between">
                      <Typography color="black" className="font-bold text-lg">
                        Corporate Details: 💼
                      </Typography>
                      <IconButton
                        variant="text"
                        color="blue-gray"
                        onClick={closeDrawerRight}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </IconButton>
                    </div>
                    {selectedEmployee ? (
                      <List className='p-0 rounded-xl border border-gray-300 border-b-0 custom-shadow'>
                        <ListItem className='pb-0'>
                          <div>
                            <Typography variant="h6" color="black">
                              Reporting Manager:
                            </Typography>
                            <Typography variant="small" color="gray" className="font-normal text-sm">
                              {selectedEmployee.Reporting_Manager || 'Not available'}
                            </Typography>
                          </div>
                        </ListItem>
                        <ListItem className='py-1'>
                          <div>
                            <Typography variant="h6" color="blue-gray">
                              Department:
                            </Typography>
                            <Typography variant="small" color="gray" className="font-normal text-sm">
                              {selectedEmployee.Department || 'Not available'}
                            </Typography>
                          </div>
                        </ListItem>
                        <ListItem className='py-1'>
                          <div>
                            <Typography variant="h6" color="blue-gray">
                              Office Contact:
                            </Typography>
                            <Typography variant="small" color="gray" className="font-normal text-sm">
                              {selectedEmployee.Office_Number || 'Not available'}
                            </Typography>
                          </div>
                        </ListItem>
                        <ListItem className='pt-1 pb-2'>
                          <div>
                            <Typography variant="h6" color="blue-gray">
                              Office Email:
                            </Typography>
                            <Typography variant="small" color="gray" className="font-normal text-sm">
                              {selectedEmployee.Office_Email || 'Not available'}
                            </Typography>
                          </div>
                        </ListItem>
                      </List>
                    ) : (
                      <SkeletonRow count={10} />
                    )}
                  </div>

                  <div className='mt-4'>
                    <Typography color="black" className="font-bold text-lg">
                      Provide Access: 🔑
                    </Typography>
                    <div className="p-2 rounded-xl border border-gray-300 border-b-0 custom-shadow">
                      {/* Role Selection */}
                      <div className="flex items-center space-x-4">
                        <Typography variant="h6" color="black">
                          Role:
                        </Typography>
                        <select
                          className="font-normal text-blue-gray bg-white border border-gray-500 rounded-md p-2"
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                        >
                          <option value="">Choose</option>
                          <option value="Sales" className="font-normal text-md">Sales 💼</option>
                          <option value="Admin" className="font-normal text-md">Admin ⚙️</option>
                          <option value="Post Sales" className="font-normal text-md">Post Sales 👨‍💻</option>
                          <option value="Employee" className="font-normal text-md">Employee 👨‍💻</option>
                        </select>
                      </div>
                      {selectedEmployee ? (
                        <div className="flex flex-col">
                          <div className="flex items-center my-2">
                            <Typography variant="h6" color="black">
                              Employee Id:
                            </Typography>
                            <span className="inline-flex items-center rounded-md bg-black ml-2 px-2 py-1.5 text-xs font-bold text-white ring-1 ring-inset ring-green-600/20">
                              {selectedEmployee.Employee_Id}
                            </span>
                          </div>
                          <Button size="sm" className="bg-black p-3" onClick={grantAccess}>
                            Provide
                          </Button>
                          <p className='text-red-500 text-sm'>{err}</p>
                        </div>
                      ) : (
                        <SkeletonRow count={10} />
                      )}
                    </div>
                  </div>
                  <div className='mt-4'>
                    <Typography color="black" className="font-bold text-lg">
                      Provided Access: 🔓
                    </Typography>
                    <div className="p-2 rounded-xl border border-gray-300 border-b-0 custom-shadow">
                      {employeeRoles.length > 0 ? (
                        employeeRoles.map((role, index) => (
                          <div className='py-1' key={index}>
                            <Typography color="black" className="font-bold text-md flex items-center">
                              <li className='list-none'>[{index}] {role}</li>
                              <TrashIcon
                                className="w-6 h-6 text-red-300 cursor-pointer ml-2 hover:text-red-600"
                                onClick={() => handleDelete(role)} />
                            </Typography>
                          </div>
                        ))
                      ) : (
                        <div>
                          <Typography color="gray" className="font-bold text-md">
                            No Access Provided Yet
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </Drawer>

              </div>
            </CardBody>

          </div>
        </Card>
      </div>
    </>
  );
};

export default AccessControl;
