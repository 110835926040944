import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutRole  } from "../redux/slice/roles";

const useLogout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        localStorage.removeItem('Access Token');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('loginTime');
        localStorage.removeItem('Role');
        localStorage.removeItem('toastShown');
        dispatch(logoutRole());
        navigate('/Employeelogin', { replace: true });
    };

    return handleLogout;
};

export default useLogout;
