import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ role, allowedRoles, children }) => {
  
  const location = useLocation();

  // Allow the login page to render even if role is empty
  if (location.pathname === "/Employeelogin") {
    return children;
  }

  // If role is not available and we're not on the login page, redirect to login
  if (role === '' || role === undefined) {
    return <Navigate to="/Employeelogin" replace />;
  }

  // Check if the user's role is allowed for the route
  const isAllowed = Array.isArray(role) && allowedRoles.some((currentRole) => role.includes(currentRole));

  if (!isAllowed) {
    return <Navigate to="/404" replace />;
  }

  return children;
};

export default ProtectedRoute;
