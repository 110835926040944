import config from "../../config.js";
import axios from "axios";

export const fetchLeads = async (Employee_Id, page, searchQuery, selectedDate, selectedState, selectedExperience, selectStatus) => {
    const token = localStorage.getItem("Access Token");
    const response = await axios.get(`${config.hostedUrl}/selfGenLeads/leads/${Employee_Id}`, {
        params: {
            page,
            limit: 20,
            searchQuery,
            dateQuery: selectedDate,
            stateQuery: selectedState,
            experienceQuery: selectedExperience,
            statusQuery: selectStatus,
        },
        headers: {
            Authorization: token,
        }
    });
    return response;
};

export const updateLeadStatus = async (leadId, status) => {
    const token = localStorage.getItem("Access Token");
    const response = await axios.post(`${config.hostedUrl}/selfGenLeads/updateLeadStatus`,
        { leadId, status },
        {
            headers: {
                Authorization: token,
            }
        });
    return response;
};


