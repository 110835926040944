import React from 'react'
import LottieFile from '../LottieFile.js';
import { Card } from '@material-tailwind/react';

const ExpenseTracker = () => {
  return (
    <>
        <div className="flex w-full h-full mt-1 opacity-1">
        <LottieFile />
        <Card className="h-full w-full mx-2 opacity-1 bg-custom shadow-none">
          <h1>Expense Tracker</h1>
        </Card>
        </div>
    </>
  )
}

export default ExpenseTracker;
