import axios from "axios";
import config from "../config";

const fetchLeads = async () => {
  
  try {
    const page = 1;
    const limit = 20;
    const employeeId = "EGE0501";
    const response = await axios.get(`${config.hostedUrl}/selfGenLeads/leads/${employeeId}`, {
      params: { page, limit },
    });
    // console.log(response.data);
    return response.data;
  } catch (err) {
    throw new Error(err.response?.data?.error || "Failed to fetch leads.");
  }
};

export default fetchLeads;