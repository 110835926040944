import React, { useEffect, useState } from 'react';
import LottieFile from './LottieFile';
import { Card, CardBody, Typography } from '@material-tailwind/react';
import Page404 from './Page404';
import { useDispatch, useSelector } from "react-redux";
import { fetchAnnouncements } from './api/notificationApi';
import { fetchEmployeesDetails } from './redux/slice/employeeSlice';

const Notification = () => {
  const [notificationData, setNotificationData] = useState(null);
  
  const allDetails = useSelector(state => state.employeesDetails);
  const { Employee_Id, Department } = allDetails.data || {};
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployeesDetails());
  }, [dispatch]);

  useEffect(() => {
    const loadAnnouncements = async () => {
      try {
        const response = await fetchAnnouncements(Employee_Id, Department);
        setNotificationData(response.data);
      } catch (error) {
        console.error("Failed to load announcements:", error);
      }
    };
    loadAnnouncements();
  }, [dispatch, Employee_Id, Department]);

  return (
    <>
      <div className="flex w-full h-full my-1 opacity-1">
        <LottieFile />
        <Card className="h-full w-full mx-2 opacity-1 bg-custom shadow-none">

          {/* Sticky header for the member list and search bar */}
          <div className="mt-1 pt-3 pb-4 z-10 px-4 rounded-border bg-transparent">
            <div className='flex flex-col gap-2'>
              <Typography variant="text" color="blue-gray" className="whitespace-nowrap font-bold"> 
                Announcement Date: <span className='font-normal text-blue-gray-500'>{notificationData?.date || 'NA'} 🗓️</span>
              </Typography>

              <Typography variant="text" color="blue-gray" className="whitespace-nowrap font-bold">
                Sent By: <span>{notificationData?.sentBy || 'NA'}</span>
              </Typography>
            </div>
          </div>

          {/* Scrollable card body for the table */}
          <div className="mt-1">
            <CardBody className="h-screen overflow-y-auto px-4 py-3 text-gray-700 rounded-xl border border-gray-300 bg-transparent">
              <div className="mb-2 w-1/2">
                <Typography variant="lg" color="blue-gray" className="font-bold">Subject: {notificationData?.title || 'NA'}</Typography>
              </div>
              <Typography variant="sm" color="gray"
                className="font-normal text-blue-gray-500 mb-3">Dear Folks,🍻
              </Typography>
              <div className="mb-6">
                <Typography variant="sm" color="gray" className="font-normal text-blue-gray-500">
                  {notificationData ? notificationData?.message : <Page404 />}
                </Typography>
              </div>
              <div className="mt-8">
                <Typography className="font-bold text-black">Best Regards,</Typography>
                <Typography variant="sm" color="gray"
                  className="font-normal text-blue-gray-500">{notificationData?.sentBy || 'Loading..'}</Typography>
                <Typography variant="sm" color="gray"
                  className="font-normal text-blue-gray-500 mb-3">EdiGlobe</Typography>
              </div>
            </CardBody>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Notification;
