import React from 'react'
import LottieFile from '../LottieFile.js';
import { Card } from '@material-tailwind/react';
import Editor from '../../NotificationTab.js'

const PostNotify = () => {
  return (
    <>
        <div className="flex w-full h-full mt-1 opacity-1">
        <LottieFile />
        <Card className="h-full w-full mx-2 opacity-1 bg-custom shadow-none">
          <Editor/>
        </Card>
        </div>
    </>
  )
}

export default PostNotify;
