import config from "../../config.js";
import axios from "axios";

export const provideAccessControl = async () => {
    const token = localStorage.getItem("Access Token");
    const response = await axios.get(`${config.hostedUrl}/accesscontrol/all`, {
        headers: {
            Authorization: token,
        }
    });
    return response;
};


export const fetchAccessDetails = async (Employee_Id) => {
    const token = localStorage.getItem("Access Token");
    const response = await axios.get(`${config.hostedUrl}/accessControl/accessById/${Employee_Id}`, {
        headers: {
            Authorization: token,
        }
    });
    return response;
};
