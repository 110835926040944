import React from 'react'
import SideBar from './All Components/Roles/SideBar'
import { Outlet } from 'react-router-dom';

const App = () => {

    return (
        <div className='flex'>
            <SideBar />
            <Outlet/>
        </div>
    )
}

export default App
