import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: [],
  selectedRole: "",
};

const roles = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action) => { 
      const {role} = action.payload
      state.role = role;
    },
    setSelectedRole: (state, action) => {
      const {selectedRole} = action.payload
      state.selectedRole = selectedRole;
    },
    logoutRole: (state) => {
      state.selectedRole = "";
      state.role = [];
    },
  },

});

export const { setRole, setSelectedRole, logoutRole } = roles.actions;
export default roles.reducer;