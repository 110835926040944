import React, { useCallback, useEffect, useState } from 'react'
import LottieFile from './LottieFile';
import './style.css';
import config from "../config.js";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Card, Input, Typography, CardBody, Button, Dialog, DialogBody, DialogFooter, DialogHeader } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeesDetails } from "../All Components/redux/slice/employeeSlice.js";
import { fetchDirectPayment, fetchDpsById } from './api/directPaymentRazorpay.js';
import { FaRegCalendarCheck } from "react-icons/fa6";
import { FaUniversity } from "react-icons/fa";
import { useForm } from 'react-hook-form';
import { BiSolidSelectMultiple } from "react-icons/bi";
import { MdOutlineError } from "react-icons/md";
import Typewriter from 'typewriter-effect';
import axios from 'axios';


const TABLE_HEAD = ["Payment Id", "Amount Paid", "Student Name", "Email", "Contact", "Payment Date", "Action"];

const SkeletonRow = () => (
    <tr className="animate-pulse">
        {TABLE_HEAD.map((_, index) => (
            <td key={index} className="p-4">
                <div className="h-6 bg-gray-300 rounded w-3/4 mx-auto"></div>
            </td>
        ))}
    </tr>
);
const SkeletonLoader = () => (
    <>
        {Array.from({ length: 20 }).map((_, index) => (
            <SkeletonRow key={index} />
        ))}
    </>
);

const DirectPayments = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [size, setSize] = useState(false);
    const [apiRecords, setApiRecords] = useState(null);
    const [apiDpsData, setApiDpsData] = useState(null);
    const [dpsStatus, setDpsStatus] = useState([]);
    const [dpsStatusLoaded, setDpsStatusLoaded] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");

    const dispatch = useDispatch();

    const handleOpen = (value) => setSize(value);
    const { register, handleSubmit, reset, watch, formState: { errors, isSubmitting } } = useForm();
    const watchedFields = watch();
    const isFormEmpty = !Object.values(watchedFields).some((value) => value);

    const allDetails = useSelector(state => state.employeesDetails);
    const { Employee_Id, Employee_Name } = allDetails.data || {};

    useEffect(() => {
        dispatch(fetchEmployeesDetails());
    }, [dispatch]);

    const fetchDpsStatus = useCallback(async () => {
        try {
            const response = await fetchDpsById(Employee_Id);
            setDpsStatus(response);
            setDpsStatusLoaded(true);
        } catch (error) {
            console.error("Error fetching DPS status:", error);
            setDpsStatusLoaded(false);
        }
    }, [Employee_Id]);

    const fetchapiDpsData = useCallback(async () => {
        try {
            const response = await fetchDirectPayment(Employee_Id);
            setApiDpsData(response);
        } catch (error) {
            console.error("Error fetching DPS status:", error);
        }
    }, [Employee_Id]);

    useEffect(() => {
        if (!Employee_Id) return;
        const fetchData = async () => {
            await fetchDpsStatus(Employee_Id);
            if (dpsStatusLoaded) {
                await fetchapiDpsData(Employee_Id);
            }
        };
        fetchData();
    }, [Employee_Id, dpsStatusLoaded, fetchDpsStatus]);

    const onSubmit = async (data) => {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        const formattedDate = formatDateTime();
        const { notes } = apiRecords;
        const notesEmployeeRecords = {
            fullName: notes.full_name,
            contactNumber: notes.contact_number,
            whatsappNumber: notes.whatsapp_number,
            studentPersonalEmail: notes.email,
            employeeId: notes.referral_code,
        }
        const dataWithOldRecords = {
            ...data,
            DateOfDpsFilled: formattedDate,
            Employee_Name: Employee_Name,
            studentName: notesEmployeeRecords.fullName,
            contactNumber: notesEmployeeRecords.contactNumber,
            whatsAppNumber: notesEmployeeRecords.whatsappNumber,
            studentPersonalEmail: notesEmployeeRecords.studentPersonalEmail,
            Employee_Id: notesEmployeeRecords.employeeId,
        };
        await delay(1000);
        try {
            const token = localStorage.getItem("Access Token");
            if (dpsStatus.filter((dps) =>
                dps.studentPersonalEmail === dataWithOldRecords.studentPersonalEmail &&
                dps.domainOpted === dataWithOldRecords.domainOpted).length > 0) {
                alert(`You have already filled DPS for this student with ${dataWithOldRecords.domainOpted} domain!`);
            } else {
                const response = await axios.post(`${config.hostedUrl}/dpsForm/dpsFormData`, dataWithOldRecords, {
                    headers: {
                        Authorization: token,
                        "Content-Type": "application/json",
                    },
                });
                alert("DPS data updated successfully!");
                setDpsStatus((prev) => (Array.isArray(prev) ? [...prev, response.data] : [response.data]))
                reset();
                handleOpen(null);
            }
        } catch (error) {
            console.log(error.response?.data?.message || "An error occurred");
        }
    };

    const handleSubmitAndRefresh = handleSubmit(async (data) => {
        try {
            await onSubmit(data);
            await fetchDpsStatus();
        } catch (error) {
            console.error("Error in combined submission:", error);
        }
    });

    let filledCount = 0;
    let notFilledCount = 0;

    const filteredPayments = apiDpsData?.filter(payment => {
        const matchesSearch =
            payment.notes?.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            payment.notes?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            payment.notes?.contact_number.includes(searchQuery);

        const isDpsFilled = dpsStatus?.some(dps => dps.studentPersonalEmail === payment.notes.email && dps.domainOpted);

        // Count Filled and Not Filled DPS
        if (isDpsFilled) {
            filledCount++;
        } else {
            notFilledCount++;
        }

        if (selectedStatus === "filled") {
            return matchesSearch && isDpsFilled;
        } else if (selectedStatus === "notFilled") {
            return matchesSearch && !isDpsFilled;
        }
        return matchesSearch;
    });

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    function formatDateString(dateString) {
        if (!dateString) return null;

        // Normalize date string by removing 'T' and 'Z' if present
        const normalizedDateString = dateString.replace('T', ' ').replace('Z', ' ');

        // Try parsing the date in different formats
        const parts = normalizedDateString.split(/[-/,\s:]/).map(part => part.trim());

        let day, month, year;

        if (parts.length >= 3) {
            if (normalizedDateString.includes('-')) {
                // Handle 'YYYY-MM-DD' or 'DD-MM-YYYY'
                if (parts[0].length === 4) {
                    [year, month, day] = parts;
                } else {
                    [day, month, year] = parts;
                }
            } else if (normalizedDateString.includes('/')) {
                // Handle 'DD/MM/YYYY' or 'MM/DD/YYYY'
                if (parts[2].length === 4) {
                    [day, month, year] = parts;
                } else {
                    [month, day, year] = parts;
                }
            }
        }

        // Validate and format the date
        if (!day || !month || !year || isNaN(new Date(`${year}-${month}-${day}`).getTime())) {
            return null;
        }

        const date = new Date(`${year}-${month}-${day}`);

        return `${date.getDate()}${getOrdinalSuffix(date.getDate())} ${date.toLocaleString('en-US', { month: 'long' })} ${date.getFullYear()}`;
    }

    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }

    const formatDateTime = () => {
        const date = new Date();
        const todayDate = date.toLocaleDateString("en-US", { day: 'numeric', month: 'short', year: 'numeric' }) + ' ' + date.toLocaleDateString("en-US", { weekday: 'long' });
        return todayDate;
    };

    const handleDPSClick = (payment) => {
        setApiRecords(payment);
        handleOpen("xl");
    }

    return (
        <>
            <div className="flex w-full h-full mt-1 opacity-1">
                <LottieFile />
                <Card className="h-full w-full mx-2 opacity-1 bg-custom shadow-none">
                    {/* Sticky header for the member list and search bar */}
                    <div className="flex flex-col items-center justify-between gap-4 md:flex-row p-1 rounded-border">
                        {/* Search Input */}
                        <div className="pt-1 mb-2 flex flex-col items-center justify-between gap-4 md:flex-row">
                            <Typography variant="text" color="blue-gray" className="whitespace-nowrap font-bold mt-1 flex items-center text-center md:text-left">
                                DPS Filled: <span className='text-green-500 ml-2'> {filledCount}</span>
                            </Typography>
                            <Typography variant="text" color="blue-gray" className="whitespace-nowrap font-bold mt-1 flex items-center text-center md:text-left">
                                DPS Not Filled: <span className='text-red-800 ml-2'> {notFilledCount}</span>
                            </Typography>
                        </div>
                        <div className="w-full md:w-72">
                            <Input
                                className="rounded-md focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                value={searchQuery} onChange={handleSearchChange}
                                labelProps={{ className: "hidden" }}
                                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                                placeholder="Search Student"
                            />
                        </div>

                        {/* Dropdown Filter for Filled / Not Filled */}
                        <div className="w-full md:w-56">
                            <select
                                className="w-full p-2 border border-gray-300 rounded-md focus:ring-gray-900/10 focus:outline-none"
                                value={selectedStatus} onChange={handleStatusChange}
                            >
                                <option value="">All</option>
                                <option value="filled">Filled</option>
                                <option value="notFilled">Not Filled</option>
                            </select>
                        </div>
                    </div>

                    {/* Scrollable card body for the table */}
                    <div className="mt-1">
                        <CardBody className="pt-0 h-screen overflow-y-auto px-0 text-gray-700 rounded-xl border border-gray-300">
                            <div className="overflow-x-auto">
                                <table className="min-w-full table-auto text-left border-collapse">
                                    <thead className="bg-black sticky top-0 z-10">
                                        <tr>
                                            {TABLE_HEAD.map((head, index) => (
                                                <th
                                                    key={head}
                                                    className={`border-y border-blue-gray-100 p-4 ${index === 0 ? 'text-start' : 'text-center'}`}
                                                >
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-bold leading-none text-white"
                                                    >
                                                        {head}
                                                    </Typography>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(!filteredPayments) ? (
                                            <SkeletonLoader />
                                        ) : apiDpsData ? (
                                            filteredPayments.length > 0 ? (
                                                filteredPayments.map((payment) => {
                                                    const isDpsFilled = dpsStatus?.some(dps => dps.studentPersonalEmail === payment.notes.email && dps.domainOpted);
                                                    return (
                                                        <tr className="border-b border-blue-gray-50" key={payment.id}>
                                                            <td className="p-3">
                                                                <Typography variant="small" color="blue-gray" className="font-normal text-start">
                                                                    {payment.id}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-3">
                                                                <Typography variant="small" color="blue-gray" className="text-center  font-normal">
                                                                    {payment.amount}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-3">
                                                                <Typography variant="small" color="blue-gray" className="text-center  font-normal">
                                                                    {payment.notes?.full_name}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-3">
                                                                <Typography variant="small" color="blue-gray" className="text-center  font-normal">
                                                                    {payment.notes?.email}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-3">
                                                                <Typography variant="small" color="blue-gray" className="text-center  font-normal">
                                                                    {payment.notes?.contact_number}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-3">
                                                                <Typography variant="small" color="blue-gray" className="text-center  font-normal">
                                                                    {formatDateString(payment.created_at)}
                                                                </Typography>
                                                            </td>
                                                            <td className="p-2">
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal flex items-center justify-between" >
                                                                    <div className="flex-1 flex justify-center gap-1">
                                                                        <Button
                                                                            className={`text-white ${isDpsFilled ? 'bg-green-500' : 'bg-black'}`}
                                                                            disabled={isDpsFilled}
                                                                            onClick={() => handleDPSClick(payment)}>
                                                                            {isDpsFilled ? 'Filled' : 'Fill DPS'}
                                                                        </Button>
                                                                    </div>
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={TABLE_HEAD.length} className="text-center p-3">
                                                        No leads found
                                                    </td>
                                                </tr>
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={TABLE_HEAD.length} className="text-center p-4">
                                                    Error in loading data
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <Dialog open={size === "xl"} size={size || "md"} handler={handleOpen}
                                    className="h-full overflow-y-auto mt-2"
                                >
                                    <DialogHeader className="text-center sm:text-left">
                                        <div className="flex items-center justify-between w-full">
                                            <h1 className="flex">
                                                Fill your 📝&nbsp;
                                                <Typewriter
                                                    options={{
                                                        strings: [`<span style="color: #16a34a";">Pending details to update DPS!</span>`],
                                                        autoStart: true,
                                                        loop: true,
                                                        delay: 200, // Adjust typing speed
                                                        deleteSpeed: 100, // Adjust delete speed
                                                    }}
                                                />
                                            </h1>
                                            <div className="absolute right-4">
                                                <Button className="bg-black w-auto py-4 px-4" onClick={() => handleOpen(null)}>
                                                    <span>Back To HomePage 🏠</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </DialogHeader>
                                    <DialogBody>
                                        <div className="pt-3 pb-4 z-10 px-4 rounded-border bg-transparent">
                                            <form onSubmit={handleSubmitAndRefresh}>
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-6">
                                                    <div>
                                                        <label htmlFor="text">
                                                            <Typography
                                                                variant="medium"
                                                                color="blue-gray"
                                                                className="block font-medium mb-1">
                                                                College Name
                                                            </Typography>
                                                        </label>
                                                        <div className="relative">
                                                            <FaUniversity className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                                                            <input
                                                                placeholder='Enter College Name'
                                                                type="text"
                                                                {...register("collegeName", { required: true, minLength: 3, maxLength: 70 })}
                                                                className="w-full py-2 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-gray-500 focus:ring-gray-900/10"
                                                                autocomplete="off"
                                                                disabled={isSubmitting}
                                                            />
                                                        </div>
                                                        <div className="mt-2 h-2">
                                                            {errors.collegeName && (
                                                                <p className="text-red-500 text-xs flex align-middle gap-1">
                                                                    <MdOutlineError className="w-4 h-4 shrink-0" />
                                                                    <span className="truncate">College name is required</span>
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="text">
                                                            <Typography
                                                                variant="medium"
                                                                color="blue-gray"
                                                                className="block font-medium mb-1">
                                                                Date of Registration
                                                            </Typography>
                                                        </label>
                                                        <div className="relative">
                                                            <FaRegCalendarCheck className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                                                            <input
                                                                placeholder='Enter Registration Date'
                                                                type="date"
                                                                {...register("DateOfRegistration", { required: true })}
                                                                className="w-full py-2 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-red-500 focus:ring-gray-900/10"
                                                                disabled={isSubmitting}
                                                                autocomplete="off"
                                                            />
                                                        </div>
                                                        <div className="mt-2 h-2">
                                                            {errors.DateOfRegistration && (
                                                                <p className="text-red-500 text-xs mt-2 flex align-middle gap-1">
                                                                    <MdOutlineError className="w-4 h-4" />Date of Registration is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="text">
                                                            <Typography
                                                                variant="medium"
                                                                color="blue-gray"
                                                                className="block font-medium mb-1">
                                                                Department
                                                            </Typography>
                                                        </label>
                                                        <div className="relative">
                                                            <BiSolidSelectMultiple className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                                                            <select {...register("department", { required: true })}
                                                                className="w-full py-2.5 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent focus:border-gray-500 focus:ring-gray-900/10"
                                                                disabled={isSubmitting}
                                                                placeholder="Select Department">
                                                                <option value="">Choose department</option>
                                                                <option value="Engineering">Engineering</option>
                                                                <option value="MBA">MBA</option>
                                                                <option value="B.Com/BBA">B.Com/BBA</option>
                                                                <option value="Phar.">Phar.</option>
                                                                <option value="other">Other</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-2 h-2">
                                                            {errors.department && (
                                                                <p className="text-red-500 text-xs mt-2 flex align-middle gap-1">
                                                                    <MdOutlineError className="w-4 h-4" />Department is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="text">
                                                            <Typography
                                                                variant="medium"
                                                                color="blue-gray"
                                                                className="block font-medium mb-1">
                                                                Stream
                                                            </Typography>
                                                        </label>
                                                        <div className="relative">
                                                            <BiSolidSelectMultiple className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                                                            <select {...register("stream", { required: true })}
                                                                className="w-full py-2.5 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-gray-500 focus:ring-gray-900/10"
                                                                disabled={isSubmitting}
                                                            >
                                                                <option value="">Choose Stream</option>
                                                                <option value="CSE/IT">CSE/IT</option>
                                                                <option value="ECE/EEE">ECE/EEE</option>
                                                                <option value="Mechanical">Mechanical</option>
                                                                <option value="Civil">Civil</option>
                                                                <option value="BioTech">BioTech</option>
                                                                <option value="Aeronautical">Aeronautical</option>
                                                                <option value="other">Other</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-2 h-2">
                                                            {errors.stream && (
                                                                <p className="text-red-500 text-xs mt-2 flex align-middle gap-1">
                                                                    <MdOutlineError className="w-4 h-4" />Stream is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="text">
                                                            <Typography
                                                                variant="medium"
                                                                color="blue-gray"
                                                                className="block font-medium mb-1">
                                                                Graduation Year
                                                            </Typography>
                                                        </label>
                                                        <div className="relative">
                                                            <BiSolidSelectMultiple className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                                                            <select {...register("graduationYear", { required: true })} className="w-full py-2.5 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-gray-500 focus:ring-gray-900/10"
                                                                disabled={isSubmitting}
                                                            >
                                                                <option value="">Choose Graduation Year</option>
                                                                <option value="1st">1st</option>
                                                                <option value="2nd">2nd</option>
                                                                <option value="3rd">3rd</option>
                                                                <option value="4th">4th</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-2 h-2">
                                                            {errors.graduationYear && (
                                                                <p className="text-red-500 text-xs mt-2 flex align-middle gap-1">
                                                                    <MdOutlineError className="w-4 h-4" />Graduation Year is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="text">
                                                            <Typography
                                                                variant="medium"
                                                                color="blue-gray"
                                                                className="block font-medium mb-1">
                                                                Domain Opted
                                                            </Typography>
                                                        </label>
                                                        <div className="relative">
                                                            <BiSolidSelectMultiple className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                                                            <select {...register("domainOpted", { required: true })} className="w-full py-2.5 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-gray-500 focus:ring-gray-900/10"
                                                                disabled={isSubmitting}
                                                            >
                                                                <option value="">Choose Domain</option>
                                                                <option value="Artificial Intelligence">Artificial Intelligence</option>
                                                                <option value="Machine Learning">Machine Learning</option>
                                                                <option value="Data Science">Data Science</option>
                                                                <option value="Web Development">Web Development</option>
                                                                <option value="JAVA">JAVA</option>
                                                                <option value="Cyber Security">Cyber Security</option>
                                                                <option value="Amazon Web Services">Amazon Web Services</option>
                                                                <option value="Python">Python</option>
                                                                <option value="Android Development">Android Development</option>
                                                                <option value="Stock Marketing">Stock Marketing</option>
                                                                <option value="Business Analytics">Business Analytics</option>
                                                                <option value="Very Large Scale Integration">Very Large Scale Integration</option>
                                                                <option value="NANO Science">NANO Science</option>
                                                                <option value="Bio-informatics">Bio-informatics</option>
                                                                <option value="Genetic Engineering">Genetic Engineering</option>
                                                                <option value="IoT-Robotics">IoT-Robotics</option>
                                                                <option value="Car Designing">Car Designing</option>
                                                                <option value="AutoCAD">AutoCAD</option>
                                                                <option value="Hybrid & Electric Vehicles">Hybrid & Electric Vehicles</option>
                                                                <option value="Finance">Finance</option>
                                                                <option value="Digital Marketing">Digital Marketing</option>
                                                                <option value="Construction Planning">Construction Planning</option>
                                                                <option value="Embedded Systems">Embedded Systems</option>
                                                                <option value="Human Resources">Human Resources</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-2 h-2">
                                                            {errors.domainOpted && (
                                                                <p className="text-red-500 text-xs mt-2 flex align-middle gap-1">
                                                                    <MdOutlineError className="w-4 h-4" />Domain is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="text">
                                                            <Typography
                                                                variant="medium"
                                                                color="blue-gray"
                                                                className="block font-medium mb-1">
                                                                Domain Type
                                                            </Typography>
                                                        </label>
                                                        <div className="relative">
                                                            <BiSolidSelectMultiple className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                                                            <select {...register("domainType", { required: true })} className="w-full py-2.5 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-gray-500 focus:ring-gray-900/10"
                                                                disabled={isSubmitting}
                                                            >
                                                                <option value="">Choose Domain Type</option>
                                                                <option value="Self Learning">Self Learning</option>
                                                                <option value="Self Learning with ADD ON">Self Learning with ADD ON</option>
                                                                <option value="Expert Lead Program">Expert Lead Program</option>
                                                                <option value="Expert Lead Program with ADD ON">Expert Lead Program with ADD ON</option>
                                                                <option value="Offline">Offline</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-2 h-2">
                                                            {errors.domainType && (
                                                                <p className="text-red-500 text-xs mt-2 flex align-middle gap-1">
                                                                    <MdOutlineError className="w-4 h-4" />Domain Type is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="text">
                                                            <Typography
                                                                variant="medium"
                                                                color="blue-gray"
                                                                className="block font-medium mb-1">
                                                                Amount Pitched
                                                            </Typography>
                                                        </label>
                                                        <div className="relative">
                                                            <BiSolidSelectMultiple className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                                                            <select {...register("amountPitched", { required: true })} className="w-full py-2.5 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-gray-500 focus:ring-gray-900/10"
                                                                disabled={isSubmitting}
                                                            >
                                                                <option value="">Choose Amount</option>
                                                                <option value="3500">3500</option>
                                                                <option value="2500">2500</option>
                                                                <option value="4000">4000</option>
                                                                <option value="4500">4500</option>
                                                                <option value="5000">5000</option>
                                                                <option value="5500">5500</option>
                                                                <option value="7500">7500</option>
                                                                <option value="9000">9000</option>
                                                                <option value="12000-18000">12000-18000</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-2 h-2">
                                                            {errors.amountPitched && (
                                                                <p className="text-red-500 text-xs mt-2 flex align-middle gap-1">
                                                                    <MdOutlineError className="w-4 h-4" />Amount is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="text">
                                                            <Typography
                                                                variant="medium"
                                                                color="blue-gray"
                                                                className="block font-medium mb-1">
                                                                Amount Paid
                                                            </Typography>
                                                        </label>
                                                        <div className="relative">
                                                            <BiSolidSelectMultiple className="absolute left-2 top-1/2 transform -translate-y-1/2 h-7 w-7 p-1 rounded-lg bg-blue-gray-50 text-black" />
                                                            <select {...register("amountPaid", { required: true })}
                                                                className="w-full py-2.5 pl-12 pr-3 border rounded-md border-dashed border-gray-600 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-gray-500 focus:ring-gray-900/10"
                                                                disabled={isSubmitting}
                                                            >
                                                                <option value="">Choose Amount Paid</option>
                                                                <option value="1000">1000</option>
                                                                <option value="1500">1500</option>
                                                                <option value="3500">3500</option>
                                                                <option value="4000">4000</option>
                                                                <option value="4500">4500</option>
                                                                <option value="5000">5000</option>
                                                                <option value="5500">5500</option>
                                                                <option value="7500">7500</option>
                                                                <option value="Only MNC Certificate">Only MNC Certificate</option>
                                                            </select>
                                                        </div>
                                                        <div className="mt-2 h-2">
                                                            {errors.amountPaid && (
                                                                <p className="text-red-500 text-xs mt-2 flex align-middle gap-1">
                                                                    <MdOutlineError className="w-4 h-4" />Amount Paid is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="submit" className={`bg-black text-white p-3 w-32 rounded-lg cursor-pointer ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                                                    }`} value={isSubmitting ? "Submitting..." : "Submit"} />

                                                <button
                                                    onClick={() => reset()}
                                                    className={`w-full md:w-auto ml-2 mt-4 sm:mt-0 border border-red-300 rounded-lg px-5 py-2 text-red-600 bg-white focus:outline-none focus:ring-2 focus:ring-red-700 disabled:opacity-50 disabled:cursor-not-allowed ${isSubmitting ? "hover:bg-white hover:text-red-600" : "bg-white text-red"}`}
                                                    disabled={isSubmitting || isFormEmpty}>
                                                    Reset
                                                </button>
                                            </form>
                                        </div>
                                    </DialogBody>
                                    <DialogFooter className="flex justify-between items-center">
                                        <Typography variant="small" className="font-normal text-black opacity-80">
                                            <b style={{ color: "red", fontWeight: "bold", backgroundColor: "white" }}>Note:</b>
                                            <span> Once you have filled the <mark>DPS</mark> you can't change and modify it.</span>
                                        </Typography>
                                    </DialogFooter>
                                </Dialog>
                            </div>
                        </CardBody>
                    </div>
                </Card >
            </div >
        </>
    )
}

export default DirectPayments
