import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Chip, Input, Tooltip, Typography } from "@material-tailwind/react";
import LottieFile from "./LottieFile";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { fetchLeads, updateLeadStatus } from "./api/leadApi";
import { setLeadGen } from "./redux/slice/leadGenSlice";
import { MdFilterAltOff } from "react-icons/md";
import debounce from "lodash.debounce";

const statusOptions = [
  { value: '', label: 'Select Status' },
  { value: 'DNP', label: '❌ DNP' },
  { value: 'Not Interested', label: '😐 Not Interested' },
  { value: 'Busy', label: '🕒 Busy' },
  { value: 'Disconnected', label: '📞 Disconnected' },
  { value: 'Not Called Yet', label: '🚫 Not Called Yet' },
  { value: 'Course Expensive', label: '💰 Course Expensive' },
  { value: 'Follow Up', label: '🔄 Follow Up' },
  { value: 'Forced by someone', label: '🧍 Forced by someone' },
  { value: 'Already Enrolled', label: '✅ Already Enrolled' },
  { value: 'Paid', label: '💵 Paid' },
];


const LeadsTable = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [copied, setCopied] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [debouncedFilters, setDebouncedFilters] = useState({
    selectedDate: "",
    selectedState: "",
    selectedExperience: "",
    selectStatus: ""
  });

  const dispatch = useDispatch();
  const allLeads = useSelector((state) => state.leadgenfromlp.data);
  const { totalLeads, perPage, leads, totalPages, uniqueDates, uniqueStates, uniqueExperiences, uniqueStatuses } = allLeads || {};


  const allDetails = useSelector(state => state.employeesDetails);
  const { Employee_Id, Referral_Link } = allDetails.data || {};

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Apply filtering using debounced values
  const filteredLeads = useMemo(() => {
    return leads?.filter((lead) => {
      const dateMatch = debouncedFilters.selectedDate ? lead?.date === debouncedFilters.selectedDate : true;
      const stateMatch = debouncedFilters.selectedState ? lead?.state === debouncedFilters.selectedState : true;
      const experienceMatch = debouncedFilters.selectedExperience ? lead?.experience === debouncedFilters.selectedExperience : true;
      const statusMatch = debouncedFilters.selectStatus ? lead?.status === debouncedFilters.selectStatus : true;

      return dateMatch && stateMatch && experienceMatch && statusMatch;
    });
  }, [leads, debouncedFilters]);

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedFilters({
        selectedDate,
        selectedState,
        selectedExperience,
        selectStatus
      });
    }, 500);
    handler();
    return () => handler.cancel();
  }, [selectedDate, selectedState, selectedExperience, selectStatus]);


  const triggersLeads = useCallback(
    debounce(async (Employee_Id, page, searchQuery, dateQuery, stateQuery, experienceQuery, statusQuery) => {
      if (!Employee_Id) return;
      try {
        setLoading(true);
        const response = await fetchLeads(Employee_Id, page, searchQuery, dateQuery, stateQuery, experienceQuery, statusQuery);
        dispatch(setLeadGen(response.data));
      } catch (error) {
        console.error("Error fetching leads:", error);
      } finally {
        setLoading(false);
      }
    }, 500),
    [dispatch]
  );

  const triggersUpdateStatus = useCallback(async (leadId, status) => {
    if (!leadId || !status)
      return;
    try {
      setUpdateStatusLoading((prev) => ({ ...prev, [leadId]: true }));;
      await updateLeadStatus(leadId, status);
      const updatedLeadsResponse = await fetchLeads(Employee_Id, page, searchQuery, selectedDate, selectedState, selectedExperience, selectStatus);
      dispatch(setLeadGen(updatedLeadsResponse.data)); // Dispatch the updated leads to Redux store
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setUpdateStatusLoading((prev) => ({ ...prev, [leadId]: false }));;
    }
  }, [dispatch, Employee_Id, page, searchQuery, selectedDate, selectedState, selectedExperience, selectStatus]
  );

  // Call debounced API when searchQuery or page changes
  useEffect(() => {
    triggersLeads(Employee_Id, page, searchQuery, selectedDate, selectedState, selectedExperience, selectStatus);
  }, [Employee_Id, page, searchQuery, selectedDate, selectedState, selectedExperience, selectStatus]);

  useEffect(() => {
    if (leads?.length) {
      // Initialize selectedStatus with existing lead statuses
      const initialStatus = {};
      leads.forEach((lead) => {
        initialStatus[lead._id] = lead.status || "";
      });
      setSelectedStatus(initialStatus);
    }
  }, [leads]);


  const handleRefresh = async () => {
    await triggersLeads(Employee_Id, page, searchQuery);
  };

  const handleResetFilters = () => {
    setSelectedDate("");
    setSelectedState("");
    setSelectedExperience("");
    setSelectStatus("");
  };

  const handleStatusChange = (e, leadId) => {
    const newStatus = e.target.value;
    setSelectedStatus((prev) => ({ ...prev, [leadId]: newStatus }));
  };

  const handleUpdateStatus = async (leadId, newStatus) => {
    if (!leadId || !newStatus) return;
    await triggersUpdateStatus(leadId, newStatus);
  };

  const formatPhone = (phone) => {
    const numString = phone.toString();
    return `+${numString.slice(0, 2)} ${numString.slice(2)}`;
  };

  const skeletonRows = Array.from({ length: 10 }).map((_, index) => (
    <tr key={index} className="animate-pulse">
      <td className="p-3">
        <div className="flex items-center gap-3">
          <div className="flex flex-col">
            <div className="h-5 bg-gray-300 rounded w-24 mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-32"></div>
          </div>
        </div>
      </td>
      <td className="p-3">
        <div className="h-5 bg-gray-300 rounded w-24"></div>
      </td>
      <td className="p-3">
        <div className="h-4 bg-gray-300 rounded w-40"></div>
      </td>
      <td className="p-3">
        <div className="h-4 bg-gray-300 rounded w-40"></div>
      </td>
      <td className="p-3">
        <div className="h-4 bg-gray-300 rounded w-24"></div>
      </td>
      <td className="p-3">
        <div className="h-4 bg-gray-300 rounded w-24"></div>
      </td>
      <td className="p-3">
        <div className="h-4 bg-gray-300 rounded w-28"></div>
      </td>
    </tr>
  ));

  const leadsLoading = !allLeads || allLeads.length === 0;

  return (
    <>
      <div className="flex w-full h-full my-1 opacity-1">
        <LottieFile />
        <Card className="h-full w-full mx-2 opacity-1 bg-custom shadow-none">
          <div className="z-10 px-4 pb-2 rounded-border bg-transparent relative">
            {loading && (
              <div className="absolute top-0 right-0 w-full h-1 bg-black rounded-xl opacity-70 animate-pulse transition-all duration-500 ease-in-out"></div>
            )}
            <div className='pt-3 flex flex-col items-center justify-between gap-4 md:flex-row'>
              <Typography variant="text" color="blue-gray" className="whitespace-nowrap font-bold">
                Total leads Generated: <Chip color='indigo' value={totalLeads} className='text-white bg-black font-bold inline-block pt-2' />
              </Typography>
              <div className="relative w-full md:w-72">
                <Input className="rounded-md focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10 pr-10"
                  labelProps={{ className: "hidden" }}
                  placeholder="Search Lead..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {searchQuery && (
                  <XCircleIcon
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-black cursor-pointer hover:text-gray-600"
                    onClick={() => setSearchQuery("")}
                  />
                )}
              </div>
              <div className="w-full md:w-72 flex gap-2 justify-center md:justify-end">
                <button
                  onClick={handleRefresh}
                  className="px-4 py-2 bg-black text-white rounded-lg disabled:opacity-50"
                  disabled={loading}
                >
                  {loading ? "Refreshing..." : "Refresh Leads"}
                </button>
              </div>
            </div>

            <div className='pt-3 flex flex-col items-center justify-between gap-4 md:flex-row'>
              <Typography variant="text" color="blue-gray" className="whitespace-nowrap font-bold">
                <span className="text-green-500">Per Page Lead:</span>
                <Chip color='indigo' value={perPage} className='text-white bg-black font-bold inline-block pt-2 ml-1' />

                {/* Vertical line */}
                <span className="mx-4 border-l border-gray-500 h-6 inline-block"></span>

                <span className="text-red-500">Filtered Count:</span>
                <Chip color='indigo'
                  value={loading ? "Loading..." : filteredLeads?.length}
                  className='text-white bg-black font-bold inline-block pt-2 ml-1' />
              </Typography>
              <Typography variant="text" color="blue-gray" className="mt-2 whitespace-nowrap">
                <span className="font-bold">Referral Link:</span>
                <span
                  className="text-blue-700 ml-1 cursor-pointer break-all inline-block"
                  onClick={() => handleCopy(Referral_Link)}
                  title="Click to copy"
                >
                  {Referral_Link || "Not Required"}
                </span>
                {copied && <span className="text-green-500 ml-1">Copied!</span>}
              </Typography>
            </div>

            <div className='pt-3 flex flex-col items-center justify-between gap-4 md:flex-row'>
              <div className="w-full">
                <Typography variant="md" color="blue-gray" className="whitespace-nowrap">
                  Filter By Date:
                </Typography>
                <select
                  className="block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-gray-700 shadow-sm focus:border-gray-900 focus:ring focus:ring-gray-900/10 sm:text-md"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                >
                  <option value="">All</option>
                  {uniqueDates?.map((date, index) => (
                    <option key={index} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full">
                <Typography variant="md" color="blue-gray" className="whitespace-nowrap">
                  Filter By State:
                </Typography>
                <select
                  className="block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-gray-700 shadow-sm focus:border-gray-900 focus:ring focus:ring-gray-900/10 sm:text-md"
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="">All</option>
                  {uniqueStates?.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full">
                <Typography variant="md" color="blue-gray" className="whitespace-nowrap">
                  Filter By Experience:
                </Typography>
                <select
                  className="block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-gray-700 shadow-sm focus:border-gray-900 focus:ring focus:ring-gray-900/10 sm:text-md"
                  value={selectedExperience}
                  onChange={(e) => setSelectedExperience(e.target.value)}
                >
                  <option value="">All</option>
                  {uniqueExperiences?.map((experience, index) => (
                    <option key={index} value={experience}>
                      {experience}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full">
                <Typography variant="md" color="blue-gray" className="whitespace-nowrap">
                  Filter By Status:
                </Typography>
                <select
                  className="block w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-gray-700 shadow-sm focus:border-gray-900 focus:ring focus:ring-gray-900/10 sm:text-md"
                  value={selectStatus}
                  onChange={(e) => setSelectStatus(e.target.value)}
                >
                  <option value="">All</option>
                  {uniqueStatuses?.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-full flex flex-col items-center justify-center text-center">
                <Typography variant="md" color="red" className="whitespace-nowrap text-center">
                  Reset Filter:
                </Typography>
                <MdFilterAltOff className="cursor-pointer w-8 h-8 text-red-600 hover:text-red-300" onClick={handleResetFilters} />
              </div>
            </div>

          </div>

          {/* Leads Table */}
          <div className="mt-1 max-h-[calc(100vh-150px)]">
            <CardBody className="h-screen overflow-y-auto px-0 text-gray-700 rounded-xl border border-gray-300">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {["Full Name", "Phone", "Education/College", "State/Experience", "Selected Program", "Date 📅", "Update Status"].map((head, index) => (
                      <th
                        key={head}
                        className={`border-y border-blue-gray-100 bg-blue-gray-50/50 p-3 ${index === 6 || index === 1 || index === 5 ? "text-center" : "text-left"
                          }`}
                      >
                        <Typography variant="small" className="font-bold leading-none text-black">
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-blue-gray-200">
                  {leadsLoading || loading ? (
                    skeletonRows
                  ) : filteredLeads?.length > 0 ? (
                    filteredLeads?.map((lead) => (
                      <tr key={lead._id} className="hover:bg-gray-50">
                        <td className="p-3">
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography variant="small" color="blue-gray" className="font-normal">
                                <Tooltip content={lead?.fullName || 'N/A'} placement="top">
                                  {lead?.fullName?.length > 20 ? lead?.fullName.slice(0, 20) + "..." : lead?.fullName || 'N/A'}
                                </Tooltip>
                                <Tooltip content={`Lead came ${lead?.repeatedCount || 'N/A'} times`} placement="right-end">
                                  <span className="font-bold text-center border-2 border-black rounded-md text-black ml-1 p-1 cursor-pointer">
                                    {lead?.repeatedCount || 'N/A'}
                                  </span>
                                </Tooltip>
                              </Typography>
                              <Typography variant="small" color="blue-gray" className="font-normal opacity-70">
                                {lead?.email || 'N/A'}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className="p-3">
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                                onClick={() => lead?.phone && (window.location.href = `tel:${lead.phone}`)}
                              >
                                {lead?.phone ? (lead.phone) : 'N/A'}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className="p-3">
                          <Typography variant="small" color="blue-gray" className="font-bold">
                            {lead?.collegeName || 'N/A'}
                          </Typography>
                          <Typography variant="small" color="blue-gray" className="font-normal">
                            {lead?.educationalBackground || 'N/A'}
                          </Typography>
                        </td>
                        <td className="p-3">
                          <Typography variant="small" color="blue-gray" className="font-normal">
                            {lead?.state || 'N/A'}
                          </Typography>
                          <Typography variant="small" color="blue-gray" className="font-normal font-bold">
                            {lead?.experience || 'N/A'}
                          </Typography>
                        </td>
                        <td className="p-3">
                          <Typography variant="small" color="blue-gray" className="font-normal w-24 whitespace-normal break-words">
                            <Chip
                              value={lead?.program || 'N/A'}
                              className="font-bold text-center text-white bg-black p-2.5 w-28 whitespace-normal break-words"
                            />
                          </Typography>
                        </td>
                        <td className="p-3">
                          <Typography variant="small" color="blue-gray" className="font-bold text-center text-black">
                            {lead?.date || 'N/A'}
                          </Typography>
                          <Typography variant="small" color="blue-gray" className="font-normal border text-center border-gray-600 p-1 rounded-lg">
                            {lead?.time || 'N/A'}
                          </Typography>
                        </td>
                        <td className="p-3">
                          <Typography color="blue-gray">
                            <select
                              className={`py-2 border-2 ${selectedStatus[lead._id] ? 'border-green-600' : 'border-gray-300'} rounded-md bg-white text-blue-gray font-xs`}
                              value={selectedStatus[lead._id] || ""}
                              onChange={(e) => handleStatusChange(e, lead._id)}
                              disabled={updateStatusLoading[lead._id]}
                            >
                              {statusOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <button
                              onClick={() => handleUpdateStatus(lead._id, selectedStatus[lead._id])}
                              className="w-24 ml-2 py-2 bg-black text-white rounded-lg disabled:opacity-50"
                              disabled={!selectedStatus[lead._id] || updateStatusLoading[lead._id]}
                            >
                              {updateStatusLoading[lead._id] ? "Updating..." : "Update"}
                            </button>
                          </Typography>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center py-4 text-gray-500">
                        No leads found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </CardBody>
            <div className="mt-4 flex justify-center items-center space-x-4">
              <button
                onClick={() => setPage((prev) => Math.max(1, prev - 1))}
                className="mb-2 px-4 py-2 bg-black hover:bg-gray-600 text-white rounded disabled:opacity-50"
                disabled={page === 1 || loading}
              >
                {loading ? 'Loading...' : 'Previous'}
              </button>

              {/* New pagination logic */}
              {Array.from({ length: totalPages }).map((_, index) => {
                const pageNumber = index + 1;
                let showPage = false;

                // Show first three pages
                if (pageNumber <= 3) {
                  showPage = true;
                }
                // Show current page, three pages after, and three pages before
                else if (Math.abs(pageNumber - page) <= 3) {
                  showPage = true;
                }
                // Show last page
                else if (pageNumber === totalPages) {
                  showPage = true;
                }

                if (showPage) {
                  return (
                    <button
                      key={pageNumber}
                      onClick={() => setPage(pageNumber)}
                      className={`px-2 py-1 ${pageNumber === page ? 'bg-gray-600 text-white' : 'bg-white text-gray-600'} hover:bg-gray-300 rounded`}
                    >
                      {pageNumber}
                    </button>
                  );
                } else if (pageNumber === page - 4 || pageNumber === page + 4) {
                  // Add dots for pages not shown
                  return <span key={pageNumber} className="px-2 py-1">...</span>;
                }
                return null; // Don't render anything for other pages
              })}

              <button
                onClick={() => setPage((prev) => Math.min(totalPages, prev + 1))}
                className="mb-2 px-4 py-2 bg-black hover:bg-gray-600 text-white rounded disabled:opacity-50"
                disabled={page === totalPages || loading}
              >
                {loading ? 'Loading...' : 'Next'}
              </button>
            </div>
          </div>
        </Card >
      </div >
    </>
  );

};

export default LeadsTable;