import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, List, ListItem, ListItemPrefix, Typography } from '@material-tailwind/react'
import { ChatBubbleLeftRightIcon, UserGroupIcon, LockClosedIcon, BriefcaseIcon, UserPlusIcon, ChevronUpDownIcon, ChevronDoubleRightIcon, CalendarDateRangeIcon, BanknotesIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";

const Admin = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(0);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    return (
        <>

            <Accordion open={open === 1} icon={<ChevronUpDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`} />}>
                <ListItem className="p-0">
                    <AccordionHeader onClick={() => handleOpen(1)} className={`border-b-0 p-3 ${open === 1 ? 'bg-blue-gray-50' : 'hover:bg-blue-gray-50'}`}>
                        <ListItemPrefix>
                            <BanknotesIcon className="h-5 w-5 text-black" />
                        </ListItemPrefix>
                        <Typography className="mr-auto font-normal">Payments</Typography>
                    </AccordionHeader>
                </ListItem>
                <AccordionBody className="py-1">
                    <List className="p-0">
                        <Link to="/CheckPayments">
                            <ListItem className="cursor-pointer">
                                <ListItemPrefix >
                                    <ChevronDoubleRightIcon strokeWidth={3} className="h-3 w-5" />
                                </ListItemPrefix>
                                Check Payment
                            </ListItem>
                        </Link>
                    </List>
                </AccordionBody>
            </Accordion>

            <Accordion open={open === 2} icon={<ChevronUpDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? "rotate-180" : ""}`} />}>
                <ListItem className="p-0">
                    <AccordionHeader onClick={() => handleOpen(2)} className={`border-b-0 p-3 ${open === 2 ? 'bg-blue-gray-50' : 'hover:bg-blue-gray-50'}`}>
                        <ListItemPrefix>
                            <CalendarDateRangeIcon className="h-5 w-5 text-black" />
                        </ListItemPrefix>
                        <Typography className="mr-auto font-normal">Attendance</Typography>
                    </AccordionHeader>
                </ListItem>
                <AccordionBody className="py-1">
                    <List className="p-0">
                        <Link to="/Attendance">
                            <ListItem className="cursor-pointer">
                                <ListItemPrefix>
                                    <ChevronDoubleRightIcon strokeWidth={3} className="h-3 w-5" />
                                </ListItemPrefix>
                                Attendance Details
                            </ListItem>
                        </Link>
                    </List>
                </AccordionBody>
            </Accordion>
            <hr className="border-black-gray-50" />

            <ListItem onClick={() => navigate("/onboard")} className="border-b-0 p-3 hover:bg-blue-gray-50">
                <ListItemPrefix>
                    <UserPlusIcon className="h-5 w-5 text-black" />
                </ListItemPrefix>
                Let's Onboard
            </ListItem>

            <ListItem onClick={() => navigate("/accesscontrol")} className="border-b-0 p-3 hover:bg-blue-gray-50">
                <ListItemPrefix>
                    <LockClosedIcon className="h-5 w-5 text-black" />
                </ListItemPrefix>
                Access Control
            </ListItem>

            <ListItem onClick={() => navigate("/leadsassign")} className="border-b-0 p-3 hover:bg-blue-gray-50">
                <ListItemPrefix>
                    <UserGroupIcon className="h-5 w-5 text-black" />
                </ListItemPrefix>
                Leads Assigning
            </ListItem>

            <ListItem onClick={() => navigate("/postnotification")} className="border-b-0 p-3 hover:bg-blue-gray-50">
                <ListItemPrefix>
                    <ChatBubbleLeftRightIcon className="h-5 w-5 text-black" />
                </ListItemPrefix>
                Post Notification
            </ListItem>

            <ListItem onClick={() => navigate("/expensetracker")} className="border-b-0 p-3 hover:bg-blue-gray-50">
                <ListItemPrefix>
                    <BriefcaseIcon className="h-5 w-5 text-black" />
                </ListItemPrefix>
                Expense Tracker
            </ListItem>
        </>
    );
}

export default Admin;
