import axios from 'axios';
import config from '../../config.js';

const token = localStorage.getItem("Access Token");

export const fetchAnnouncements = async (Employee_Id, Department) => {
  try {
    const response = await axios.get(`${config.hostedUrl}/notification/getNotification`, {
      headers: {
        Authorization: token,
      },
      params: {
        Employee_Id,
        Department,
      },
    });
    return response.data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching announcements:", error);
    throw error; // Rethrow the error for handling in the component
  }
};

export const postAnnouncement = async (announcementToSave) => {
  try {
    const response = await axios.post(
      `${config.hostedUrl}/notification/postNotification`,
      announcementToSave,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Return the posted data
  } catch (error) {
    console.error("Error posting announcement:", error);
    throw error; // Rethrow the error for handling in the component
  }
};

export const allNotifications = async () => {
  try {
    const response = await axios.get(`${config.hostedUrl}/notification/allNotifications`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching all notifications:", error);
    throw error; // Rethrow the error for handling in the component
  }
};
